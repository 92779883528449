import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalController, AlertController, ToastController } from '@ionic/angular';
import { FormBuilder, FormArray, FormGroup, Validators } from "@angular/forms";
import { NavparmsService } from '../../../services/navparms/navparms.service';

import { UploadProvider } from "../../../services/upload/upload";
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/firestore";

import { Storage } from "@ionic/storage";

import { ProgressBarComponent } from '../../../components/progress-bar/progress-bar';

import * as firebase from "firebase/app";
import "firebase/storage";

@Component({
  selector: 'app-button',
  templateUrl: './button.page.html',
  styleUrls: ['./button.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ButtonPage implements OnInit {
  public form: any;
  public formHome: any;
  public records: any;
  public hbuttons: any;
  public fileUploadName: any;
  public homeButtonExists: boolean = true;
  public homePageButtonNbr: string = '';
  public changeMade: string = 'no';
  public uploading: boolean = false;

  // Buttons Model
  public buttonDocID: string = "";
  public company: string = "";
  public config: string = "";
  public configid: string = "";
  public name: string = "";
  public textcolor: string = "";
  public show: boolean = true;
  public order: string = "";
  public added: string = "";
  public updated: string = "";
  public imageUrl: string = "";
  public imageFile: string = "";
  public imageCurrent: string = "";

  public configDocID: string = null;
  public homeBackgroundUrl: string = '';
  public isEditable: boolean = false;
  public googlePath: string = "";
  public homeButton1Clicked: boolean = false;
  public docIds: string;
  public configName: string;
  public buttonType: string;

  constructor(private modalCtrl: ModalController,
    private _FB: FormBuilder,
    private _ALERT: AlertController,
    public upload: UploadProvider,
    private afs: AngularFirestore,
    private storage: Storage,
    public toastController: ToastController,
    public NavparmsService: NavparmsService) {

      this.form =
      this._FB.group({
        id: [""],
        company: [""],
        config: [""],
        name: [""],
        imageButton: [""],
        textcolor: [""],
        show: [false],
        order: [""],
        added: [""],
        updated: [""]
      });

      this.changeMade = 'no';

      this.storage.get("company").then(result => {
        this.company = result;
          this.initButton();
      
      });

  }

  ngOnInit() {
  }

  initButton() {


 

    //  Here we intialize the Home Button FormArray
    this.buttonType = this.NavparmsService.getParms().buttonType;
    this.configDocID = this.NavparmsService.getParms().currentDocID;
    this.configName = this.NavparmsService.getParms().currentConfig;
    this.name = this.NavparmsService.getParms().name;
    this.textcolor = this.NavparmsService.getParms().textcolor;
    this.show = this.NavparmsService.getParms().show;
    this.order = this.NavparmsService.getParms().order;
    this.added = this.NavparmsService.getParms().added;
    this.updated = this.NavparmsService.getParms().updated;
    this.imageUrl = this.NavparmsService.getParms().imageUrl;
    this.imageFile = this.NavparmsService.getParms().imageFile;
    this.imageCurrent = this.NavparmsService.getParms().imageCurrent;

    this.homePageButtonNbr = this.NavparmsService.getParms().homePageButtonNbr;
    this.buttonDocID = this.NavparmsService.getParms().buttonDocID;
    

    this.form.get('id').setValue(this.buttonDocID);
    this.form.get('company').setValue(this.company);
    this.form.get('config').setValue(this.configDocID);
    this.form.get('name').setValue(this.name);
    this.form.get('imageButton').setValue(this.imageUrl);
    this.form.get('textcolor').setValue(this.textcolor);
    this.form.get('show').setValue(this.show);
    this.form.get('order').setValue(this.order);
    this.form.get('added').setValue(this.added);
    this.form.get('updated').setValue(this.updated);
    
    //  Check to see if this is an Add or an Update
    if (!this.buttonDocID) {
      this.isEditable = false;
    } else {
      this.isEditable = true;
    }

  }

  // Saves form data as newly added/edited record within Firebase Realtime
  // database and handles uploading of media asset to Firebase Storage

  updateButton(): void {
 
    console.log("Update button Pressed, buttonDocID:", this.buttonDocID);

    // Grab the file name from the HTML file upload component
    let elementName = "fileButton";
    this.fileUploadName = (<HTMLInputElement>document.getElementById(elementName)).files[0];

    console.log("Update button Pressed, fileUploadName:", this.fileUploadName);

    // Fire promise upload and if a file needs to be uploaded, it will
    // When done, we will update the database with the unique file name and URL.

    Promise.all([this.firebaseUpload(this.fileUploadName)]).then(data => {
      this.updateDatabase();
    });
  }

  updateDatabase() {
    // Load values from the form to update the database

    //let buttonDocID = <FormArray>this.form.get(["homeButtonRows", i, "id"]).value;
    let buttonDocID = this.buttonDocID;
    let name = this.form.controls["name"].value;
    let textcolor = this.form.controls["textcolor"].value;
    let show = this.form.controls["show"].value;
    let order = this.form.controls["order"].value;

    console.log("Home Button: ", name + " docID of button: " + buttonDocID + ", Company from Storage old way: " + this.company);
    console.log("Value of HomePageButtonNbr:", this.homePageButtonNbr);
    console.log("Value of buttonDocID:", buttonDocID);
    console.log("Value of isEditable:", this.isEditable);
    console.log("Value of name:", name);
    console.log("Value of textcolor:", textcolor);
    console.log("Value of show:", show);
    console.log("Update database with filename Url: ", this.imageUrl);
    console.log("Update database with filename File: ", this.imageFile);

    if (this.isEditable) {
      // Update an existing Config

      this.afs.firestore
        .collection(this.buttonType)
        .doc(buttonDocID)
        .update({
          name: name,
          textcolor: textcolor,
          show: show,
          order: order,
          imageUrl: !this.imageUrl ? "" : this.imageUrl,
          imageFile: !this.imageFile ? "" : this.imageFile,
          updated: firebase.firestore.FieldValue.serverTimestamp()
        })
        .then(async data => {
          const toast = await this.toastController.create({
            message: (this.buttonType == 'homebuttons' ? "Home Button - " : "Side Button - ") + name + " was successfully updated",
            duration: 3000,
            color: "medium",
            translucent: true,
            position: 'middle',
            cssClass: "toast"
          });
          toast.present();
          this.changeMade = 'update';
        })
    } else {
      // Add a new Home Button
      this.afs.firestore
        .collection(this.buttonType)
        .add({
          name: name,
          config: this.configName,
          configid: this.configDocID,
          company: this.company,
          textcolor: textcolor,
          show: show,
          order: this.homePageButtonNbr,
          imageUrl: !this.imageUrl ? "" : this.imageUrl,
          imageFile: !this.imageFile ? "" : this.imageFile,
          added: firebase.firestore.FieldValue.serverTimestamp(),
          updated: firebase.firestore.FieldValue.serverTimestamp()
        })
        .then(async data => {
          //   this.form.reset();
          this.homeButtonExists = true;
          this.buttonDocID = data.id;
          this.form.get('id').setValue(this.buttonDocID);
          console.log("Doc returned from firebase add:", data.id);
          const toast = await this.toastController.create({
            message: (this.buttonType == 'homebuttons' ? "Home Button - " : "Side Button - ") + name + " was successfully Added",
            duration: 3000,
            color: "medium",
            translucent: true,
            position: 'middle',
            cssClass: "toast"
          });
          toast.present();
          this.changeMade = 'add';
        })
        .catch(error => {
          this.displayAlert("Adding Home Button failed", error.message);
        });
    }
  }

  deleteButton() {
    console.log("Deleting Button #" + this.homePageButtonNbr);
  
    
    // ToDo: reject if Side Buttons Exist

    // Delete an existing Home Button
    console.log("Delete docID of Config: ", this.configDocID);
    console.log("Delete docID of Home Button: ", this.buttonDocID);

    this.afs.firestore
      .collection(this.buttonType)
      .doc(this.buttonDocID)
      .delete()
      .then(async data => {
        const toast = await this.toastController.create({
          message: (this.buttonType == 'homebuttons' ? "Home" : "Side") + " Button was successfully Deleted",
          duration: 3000,
          color: "medium",
          translucent: true,
          position: 'middle',
          cssClass: "toast"
        });
        toast.present();
        this.changeMade = 'delete';
      });

    this.upload.deleteImageFile(
      this.company,
      this.configName,
      this.imageFile
    );

    let elementName = "fileButton";
    (<HTMLInputElement>document.getElementById(elementName)).value = null;

    this.buttonDocID = null;
    this.name = "";
    this.textcolor = "";
    this.show = false;
    this.order = "";
    this.added = "";
    this.updated = "";
    this.imageCurrent = "";
    this.imageFile = "";
    this.imageUrl = "";



    // insert an initialized index


    this._FB.group({
      id: [""],
      company: [""],
      config: [""],
      name: [""],
      imageButton: [""],
      textcolor: [""],
      show: [false],
      order: [""],
      added: [""],
      updated: [""]
    });


    this.homeButtonExists = false;
  }

  async firebaseUpload(fileUploadName) {
    // Get out if they have not entered a new file to upload
    if (!fileUploadName) {
      console.log("Skipping Button #" + this.homePageButtonNbr + ": Because empty");
      return;
    }

    // If we are replacing an image, delete it from Firebase storage
    if (this.imageFile && this.imageFile != "") {
      this.upload.deleteImageFile(
        this.company,
        this.configName,
        this.imageFile
      );
    }

    console.log("Upload Button #" + this.homePageButtonNbr + ": " + fileUploadName);
    console.log("Gonna Upload: company: " + this.company + " name: " + this.configName + " fileuploadname: " + fileUploadName);
    this.uploading = true;
    return await this.upload
      .uploadToFirebase(this.company, this.configName, fileUploadName, true)
      .then((_uploadSnapshot: any) => {
        // _uploadSnapshot.metadata.name will contain the updated file name that has been made unique
        //  this.fileSub[i] = i;
        this.imageUrl = _uploadSnapshot.metadata.fullPath;
        this.imageFile = _uploadSnapshot.metadata.name;
        this.imageCurrent = _uploadSnapshot.metadata.fullPath;
        this.uploading = false;

        // Reload the File Input Form Field to show thumbnail
        let elementName = "fileButton";
        (<HTMLInputElement>document.getElementById(elementName)).value = null;

        // Display some Debug Info
        console.log("imageUrl " + this.homePageButtonNbr + ": ", this.imageUrl);
        console.log("imageName " + this.homePageButtonNbr + ": ", this.imageFile);
        console.log("imageCurrent " + this.homePageButtonNbr + ": ", this.imageCurrent);
      });
  }

  async displayAlert(title: string, message: string) {
    const alert = await this._ALERT.create({
      header: title,
      message: "<img src=" + message + "></img>",
      cssClass: 'alertCustomCss'
      //  buttons: ["Ok"]
    });
    await alert.present();
  }

  close() {
    this.modalCtrl.dismiss(this.changeMade);
  }

}