import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";
import * as firebase from "firebase/app";
import "firebase/storage";
import "rxjs/add/operator/map";

/*
  Generated class for the UploadProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
  providedIn: 'root',
})
export class UploadProvider {
  /**
   * The name of the folder for images
   * eg. posts/angular-is-awesome
   */
  public folder: string = "";
  loadProgress: number = 0;
  uploadURL: string = "";
  loading: any;

  constructor(public loadingCtrl: LoadingController) {
    console.log("Hello UploadProvider Provider");
  }

  async uploadToFirebase(_company, _config, _imageName, showLoading) {
    if(showLoading) {
    this.loading = await this.loadingCtrl.create({
      spinner: null,
      message:
        "<div>Uploading file: " +
        _imageName.name +
        "</div><br><div>" +
        this.loadProgress +
        "% Complete</div>"
    });
    }

    if (showLoading) {
      this.loading.present();
    }

    // Create unique file name for image
    var newName = this.makeUniqueId() + "_" + _imageName.name;

    // Firebase storage path to be /Company/Config/filename.jpg
    let path = `/${_company}/${_config}/${newName}`;

    return new Promise((resolve, reject) => {
      var fileRef = firebase.storage().ref();
      var iRef = fileRef.child(path);

      var uploadTask = iRef.put(_imageName);

      uploadTask.on(
        "state_changed",
        _snapshot => {
          this.loadProgress = Math.round(
            uploadTask.snapshot.bytesTransferred /
              uploadTask.snapshot.totalBytes *
              100
          );
          if (showLoading) {
            this.loading.message =
              "<div>Uploading file: " +
              _imageName.name +
              "</div><br><div>" +
              this.loadProgress +
              "% Complete</div>";
          }
        },
        _error => {
          reject(_error);
        },
        () => {
          // completion...
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            this.uploadURL = downloadURL;
            console.log('URL of uploaded image:' + this.uploadURL);
            uploadTask.snapshot.metadata.fullPath = this.uploadURL;
            if (showLoading) {
              this.loading.dismiss();
            }
            resolve(uploadTask.snapshot);
          });
          
        }
      );
    });
  }

  makeUniqueId() {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  deleteImageFile(_company, _config, _imageName) {
    // Set folder to the client folder for delete
    let path = `/${_company}/${_config}/${_imageName}`;
    console.log("Gonna Delete file!  at", path);

    var fileRef = firebase.storage().ref();
    var iRef = fileRef.child(path);

    var deleteTask = iRef.delete();
  }
}
