import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavparmsService {
  private parms: any;
  constructor() { }

  public setParms(parms) {
    this.parms = parms;
  }

  getParms() {
    return this.parms;
  }
}