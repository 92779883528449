import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AuthProvider } from './services/auth/auth';
import { UploadProvider } from './services/upload/upload';
import { HttpModule } from '@angular/http';
import { ButtonPageModule } from './pages/modal/button/button.module';


export const firebaseConfig = {
  apiKey           : "AIzaSyB0FLBk1BjLciUhlQff61rk1XMQVpmZUK4",
  authDomain       : "prezentproapp.firebaseapp.com",
  databaseURL      : "https://prezentproapp.firebaseio.com",
  projectId        : "prezentproapp",
  storageBucket    : "prezentproapp.appspot.com",
  messagingSenderId: "1069033408374"
};

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ButtonPageModule,
    IonicStorageModule.forRoot(),
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthProvider,
    UploadProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
