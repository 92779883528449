import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { ProgressBarComponent } from '../components/progress-bar/progress-bar';
//import { ProfileComponent } from './profile/profile.component';

@NgModule({
  imports: [
    CommonModule, 
    IonicModule
  ],
  declarations: [
    ProgressBarComponent
  ],
  exports: [
    ProgressBarComponent
  ]
})
export class SharedModule {}