import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home/:id',
    loadChildren: './pages/home/home.module#HomePageModule'
  },
  {
    path: 'home',
    loadChildren: './pages/home/home.module#HomePageModule'
  },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'logout', loadChildren: './pages/logout/logout.module#LogoutPageModule' },
  { path: 'configs-detail', loadChildren: './pages/configs-detail/configs-detail.module#ConfigsDetailPageModule' },
  { path: 'home-buttons', loadChildren: './pages/home-buttons/home-buttons.module#HomeButtonsPageModule' },
  { path: 'users', loadChildren: './pages/users/users.module#UsersPageModule' },
  { path: 'users-detail', loadChildren: './pages/users-detail/users-detail.module#UsersDetailPageModule' },
  { path: 'groups', loadChildren: './pages/groups/groups.module#GroupsPageModule' },
  { path: 'groups-detail', loadChildren: './pages/groups-detail/groups-detail.module#GroupsDetailPageModule' },
  { path: 'usage', loadChildren: './pages/usage/usage.module#UsagePageModule' },
  { path: 'settings', loadChildren: './pages/settings/settings.module#SettingsPageModule' },
  { path: 'password-reset', loadChildren: './pages/password-reset/password-reset.module#PasswordResetPageModule' },
  { path: 'feedback', loadChildren: './pages/feedback/feedback.module#FeedbackPageModule' },
  { path: 'content', loadChildren: './pages/content/content.module#ContentPageModule' },
  { path: 'content-detail', loadChildren: './pages/content-detail/content-detail.module#ContentDetailPageModule' },
  { path: 'side-buttons', loadChildren: './pages/side-buttons/side-buttons.module#SideButtonsPageModule' },
  { path: 'button', loadChildren: './pages/modal/button/button.module#ButtonPageModule' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
