import { Component } from '@angular/core';
import { Router } from "@angular/router";

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import 'firebase/auth';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  appPages: Array<{ title: string; url: any; icon: any }>; 

  public isLoggedIn : Boolean = false; 

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    afAuth: AngularFireAuth,
    private router: Router
  ) {
      // Setup Initial Side Menu Items

  this.appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Users',
      url: '/users',
      icon: 'person'
    },
    {
      title: 'Groups',
      url: '/groups',
      icon: 'people'
    },   {
      title: 'Usage',
      url: '/usage',
      icon: 'eye'
    },   {
      title: 'Feedback',
      url: '/feedback',
      icon: 'text'
    },   {
      title: 'Settings',
      url: '/settings',
      icon: 'settings'
    }
  ];

    afAuth.authState.subscribe(user => { 
      if (user) {
        console.log("Logged in");
        this.appPages.push({ 'title' : 'Logout', 'url' : '/login', 'icon' : 'exit' });
        this.isLoggedIn = true;
        this.router.navigateByUrl("/home"); }
      else {
         console.log("Not Logged in");
         this.isLoggedIn = false;
         this.appPages.pop();
         this.router.navigateByUrl("/login");}
      });

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  openPage(page) {
    
    // 1. I Home pages is selected, check to see if the user is loggedin.  If not, then send them to the Login page.
    // 2. If Logout is selected the set loggedIn to false and send them to the Login page
    // 3. Navigate to the selected page
    
    console.log(">>>>> Selected page: " + page );

      if (page == "/home") {
        if(this.isLoggedIn) {
          this.router.navigateByUrl("/home");
        } else {
          this.router.navigateByUrl("/login");
        }
      } else if (page == "/login") {
        this.isLoggedIn = false;
       // this.appPages.splice(-1, 1);
        this.router.navigateByUrl("/login");
      } else {
        this.router.navigateByUrl(page);
      }

  }
}
