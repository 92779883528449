import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from '@angular/fire/firestore';
import * as firebase from "firebase/app";
//import firebase from 'firebase/auth';
//import firebase from 'firebase/app';

import { userProfile } from '../../models/user-profile';


@Injectable({
  providedIn: 'root',
})
export class AuthProvider {
  constructor(
    public afAuth: AngularFireAuth,
    public fireStore: AngularFirestore,
  ) {}

  loginUser(email: string, password: string) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password);
  }

 // async getUserCompany(): Promise<string> {
 //   const userProfile: firebase.firestore.DocumentSnapshot = await firebase
 //   .firestore() .doc(`userProfile/${this.userId}`) .get();
 //   console.log('>>>>> User Company: ' + userProfile.data().company);
 //   
 //   return userProfile.data().company; 
 // }

  async createAdminUser(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    try {
      const adminUser: firebase.auth.UserCredential = await this.afAuth.auth.createUserWithEmailAndPassword(
        email,
        password
      );

      const userProfileDocument: AngularFirestoreDocument<userProfile> = this.fireStore.doc(`userProfile/${adminUser.user.uid}`);

      await userProfileDocument.set({
        id: adminUser.user.uid,
        email: email,
        admin: true,
        name: ' ',
        group: ' ',
        status: 'Active',
        installed: ' ',
        contentdate: ' ',
        latitude: ' ',
        longitude: ' ',
        locupdated: ' ',
        company: 'demo',
        added: ' ',
        updated: ' '
      });

      return adminUser;
    } catch (error) {
      console.error(error);
    }
  }


  logoutUser(): Promise<void> {
    return this.afAuth.auth.signOut();
  }

  resetPassword(email: string): Promise<void> {
    return this.afAuth.auth.sendPasswordResetEmail(email);
  }
}
